const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },

  // Landing Page
  landingHeading: "Send International Payments With",

  // Forgot Password
  forgotPasswordTitle: "Forgot your password?",
  forgotPasswordDescription:
    "Please enter the email address associated with your account and We will email you a link to reset your password.",
  sendRequest: "Send Request",
  returnSignIn: "Return to sign in",

  // Navigation
  dashboard: `Dashboard`,
  remittance: `Remittance`,
  receivers: `Receivers`,
  userAccount: `User Account`,
  userProfile: `User Profile`,
  userKybKyc: `User KYB/KYC`,
  support: `Support`,

  // Dashboard
  goodMorning: `Good Morning`,
  goodAfternoon: `Good Afternoon`,
  goodEvening: `Good Evening`,
  sendNow: `Send Now`,
  receiveNow: "Receive Now",
  totalLiens: "Total Contracts",
  incomingTransfers: "Incoming Transfers",
  internalTransfer: "Internal Transfers",
  outgoingTransfers: "Outgoing Transfers",
  incoming: "Incoming",
  internal: "Internal",
  outgoing: "Outgoing",
  totalRemittanceAmount: `Total Remittance Amount`,
  remittancePending: `Remittance Pending`,
  remittanceCompleted: `Remittance Completed`,
  remittanceRejected: `Remittance Rejected`,
  remittanceAmount: `Remittance Amount`,
  numberOfRemittances: `Number of Remittances`,
  created: `Created`,
  completed: `Completed`,
  pending: `Pending`,
  rejected: `Rejected`,
  daily: `Daily`,
  weekly: `Weekly`,
  monthly: `Monthly`,
  yearly: `Yearly`,
  loading: `Loading`,
  noDataAvailable: `No Data Available`,
  countrywiseRemittance: `Countrywise Remittance`,
  beneficiarywiseRemittance: `Beneficiarywise Remittance`,
  amount: `Amount`,
  count: `Count`,
  total: `Total`,

  claimPayment: "Claim Payment",
  programmedLiens: "Contract Payable",
  transactions: "Transactions",
  reports: "Reports",
  transactionReport: "Transaction Report",
  accounts: "Accounts",
  masterAccounts: "Master Accounts",
  withdrawAccounts: "Withdraw Accounts",
  newWithdraw: "New Withdraw",
  withdraw: "Withdraw",
  landingGreeting: "Hi, Welcome back",
  signInTo: "Sign in to {{name}}",
  newUser: "New User?",
  createAccount: "Create an account",
  forgotPassword: "Forgot Password?",
  login: "Login",
  alreadyUser: "Already User?",
  authRepDetails: `Authorized representative's details`,
  loginNoticePassword:
    "If you have just signed up click on the link received in email to set your password.",
  loginNoticeNoPassword:
    "If you haven't received email, click on Forgot Password",
  clickNextInfo:
    'By clicking "NEXT", you confirm that you have read and understood the STB Privacy & Cookie Policy, and agree to its terms.',
  companyDetails: "Company Details",
  companyDetailsTooltip:
    "Details as per registred with government of your country",
  regBusAddress: "Business Registration Address",
  sameAsMailingAdd: "Business Communication address same as Registered Address",
  busAddress: "Business Communication Address",

  // Claim Payment
  claim: "Claim",
  claimFundsTitle: `Claim incoming funds for {{amount}} from {{country}}({{company}})`,
  addSupportingDocs: "Add Supporting Documents",
  submitClaimReq: " Submit Claim Request",
  uploadDoc: "Upload Document",

  // Programmed Liens
  totalPendingLiens: "Total Pending Contracts",
  totalSettledLiens: "Total Settled Contracts",
  totalBorrowingsLifetime: "Total Borrowings Lifetime",
  lienId: "Contract ID",
  relatedTxn: "Related Txn",
  lienAmount: "Contract Amount",
  settledAmount: "Settled Amount",
  lender: "Lender",
  closureTxn: "Closure Txn",
  unsettled: "Unsettled",
  partiallySettled: "Partially Settled",
  fullySettled: "Fully Settled",
  liens: "Contracts",
  lien: "Contract",
  closureTxns: "Closure Transactions",
  id: "ID",

  sender: "Sender",
  maker: "Maker",
  checker: "Checker",

  externalIncoming: "External Incoming",
  externalOutgoing: "External Outgoing",
  newInternalTransfer: "New Internal Transfer",
  subAcBalance: "Sub Account Balance",

  selectPaymentMethod: "Please select the mode of payment",
  selectPaymentAccount: "Please select the account",

  // Remittance List
  remittanceList: `Remittance List`,
  list: `List`,
  newRemittance: `New Remittance`,
  createdDate: `Created Date`,
  senderMTO: `Sender MTO`,
  endBeneficiary: `End Beneficiary`,
  sentValue: `Sent Value`,
  receivedValue: "Received Value",
  status: "Status",
  paymentQr: `Payment QR Code`,
  dense: `Dense`,
  create: `Create`,

  // Create Remittance
  clientInfo: "Client Info",
  beneficiaryInfo: "Beneficiary Info",
  transferDetails: "Transfer Details",
  previewAndSubmit: "Preview and Submit",
  type: "Type",
  purpose: "Purpose",
  purposeDesc: "Purpose Description",
  documents: "Documents",
  remittanceDecl:
    "This transaction is completed by the sender in person and not on behalf of someone else.",
  addNewDoc: "Add New Document",
  documentType: "Document Type",
  documentName: "Document Name",
  upload: "Upload",
  docUploadInfo: "*You can upload images, pdf, document, or excel files.",

  cancel: "Cancel",
  dropOrSelectFile: "Drop or Select File",
  dropFilesOrClick: "Drop files here or click",
  browse: "Browse",
  thoroughYourMachine: "Through your machine",
  receiver: "Receiver",
  addNewReceiver: "Add New Receiver",
  receiverAccount: "Receiver Account",

  amountToRemit: "Amount to Remit",
  description: "Description",
  transferAmount: "Transfer Amount",
  senderMtoFee: "Sender Fee",
  beneficiaryMtoFee: "Beneficiary Fee",
  otherFee: "Other Fee",
  totalFee: "Total Fee",
  totalDepositAmount: "Total Deposit Amount",
  amountToPayout: "Amount to Payout",
  purposeOfRemittance: "Purpose of Remittance",
  conversionRate: "Conversion Rate",
  dateOfRemittance: "Date of Remittance",
  createdBy: "Created By",
  firstName: "First Name",
  invitationCode: "Invitation Code",
  lastName: "Last Name",
  businessName: "Business Name",
  primaryContactName: "Primary Contact Name",
  venmo: "Venmo",
  paypal: "Paypal",
  email: "Email",
  emailAddress: "Email Address",
  reEmailAddress: "Re-enter email address",
  primaryContactNumber: "Primary contact number",
  secondaryContactNumber: "Secondary contact number",
  bankName: "Bank Name",
  branch: "Branch",
  accountNumber: "Account Number",
  leiCode: "LEI Code",
  ibanCode: "IBAN Code",
  swiftBicCode: "SWIFT BIC Code",
  transitNumber: "Transit Number",
  abaRoutingNumber: "ABA Routing Number",
  ifscCode: "IFSC Code",
  address: "Address",
  remittanceDetails: "Remittance Details",
  senderDetails: "Sender Details",
  phone: "Phone",
  city: "City",
  province: "Province",
  country: "Country",
  targetCountry: "Target Country",
  beneficiaryDetails: "Beneficiary Details",
  financialDetails: "Financial Details",
  back: "Back",
  next: "Next",
  confirmAndSubmit: "Confirm and Submit",
  paymentOrderNumber: "Payment Order Number",
  depositType: "Deposit Type",
  senderMto: "Sender MTO",
  receiverMto: "Receiver MTO",
  date: "Date",
  details: `Details`,
  statusHistory: "Status History",
  payments: "Payments",
  remittanceInitiated: "Remittance Initiated",
  paymentReceivedFromCustomer: "Payment Received From Customer",
  outgoingPaymentOrderAssigned: "Outgoing Payment Order Assigned",
  passedAmlKycChecks: "Passed AML/KYC Checks",
  successfullyArrivedinDestinationCountry:
    "Successfully Arrived in Destination Country",
  transferredtoEndBeneficiary: "Transferred to End Beneficiary",
  remarks: "Remarks",
  updatedBy: "Updated By",
  action: "Action",
  noData: "No Data",
  senderAddress: "Sender Address",
  xrplTag: "XRPL Tag",
  edit: "Edit",

  newReceiver: "New Receiver",
  businessReceivers: "Business Receivers",
  businessReceiversList: "Business Receivers List",
  beneficiaryEmailTooltip:
    "Beneficiary will get updates regarding remittances on this email address.",
  outgoingTransfersListTitle: "Outgoing Transfers: List",
  outgoingTransferCreateTitle: "Outgoing Transfer: Create",
  outgoingTransfersList: "Outgoing Transfers List",
  outgoingTransfer: "Outgoing Transfer",
  outgoingTransfers: "Outgoing Transfers",
  newOutgoingTransfer: "New Outgoing Transfer",
  createBeneficiary: "Create Beneficiary",
  financialInfo: "Financial Information",
  businessInfo: "Business Information",
  beneficiaryCreate: "Beneficiary: Create",
  newBeneficiary: "New Beneficiary",
  beneficiary: "Beneficiary",
  beneficiaryList: "Beneficiary List",
  userInfo: "User Info",
  businessInfo: "Business Information",
  changePassword: "Change Password",
  user: "User",
  accountSettings: "Account Settings",
  name: "Name",
  primaryContact: "Primary Contact",
  secondaryContact: "Secondary Contact",
  dob: "Date of Birth",
  designation: "Designation",
  passportNumber: "Passport Number",
  postalCode: "Postal Code",
  businessDetails: "Business Details",
  doingBusinessAs: "Doing Business As",
  legalEntity: "Legal Entity",
  businessCategory: "Business Category",
  businessSubCategory: "Business Sub-category",
  companyType: "Company Type",
  companyWebsite: "Company Website",
  companyWebsiteOptional: "Company Website (optional)",
  incorporationYear: "Incorporation Year",
  registrationNumber: "Registration Number",
  registeredBusinessAddress: "Registered Business Address",
  businessCommunicationAddress: "Business Communication Address",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  saveChanges: "Save Changes",

  // KYC
  kyc: {
    kyc: "KYC",
    KybKycDocuments: "KYB/KYC Documents",
    kycForm: "KYC Form",
    startKyb: "Start KYB",
    verificationCompleted: "Verification Completed",

    notDone: {
      title: "Please verify your merchant account to get started",
      info1: ` To aid the government in combating the financing of terrorism and
            money laundering activities, federal law mandates that all financial
            institutions acquire, verify, and document information identifying
            each legal entity and individual opening an account.`,
      info2: `For your understanding: Upon opening an account, we will request
            details such as your name, address, date of birth, and relevant
            documentation pertaining to the legal entity for which the account
            is being opened.`,
      info3: `This includes information about its control persons and beneficial
            owners, facilitating our ability to identify both you and the legal
            entity you represent.`,
      info4: `Additionally, we may require identification documentation for you,
            the legal entity, and any affiliated entities or individuals
            associated with the legal entity for which the account is being
            established.`,
      info5: ` The account verification process typically requires approximately
            1-3 working days.`,
    },
    inProgress: {
      title: "Account Verification in Process",
      info: `The account verification process typically requires approximately 1-3
          working days.`,
    },

    rejected: {
      title: "Account Verification Failed",
      info: `Your KYB is failed, please click the button below to review and
            update the necessary information to get your business started.`,
      btnText: "Review and Update",
    },
  },

  pep: "Politically exposed person",
  yes: "Yes",
  no: "No",
  authRepDob: `Authorized representative's date of birth`,
  authRepDes: "Designation of authorized representative",
  residentialAddress: "Residential Address",
  close: "close",
  documentFront: "Front part of the document",
  documentBack: "Back part of the document",
  onlyShareholder: "Are you the only shareholder of the company?",
  enterOwnership: "Enter your ownership %",
  shareholder: "Shareholder",
  shareholders: "Shareholders",
  remove: "Remove",
  contact: "Contact Number",
  shareholdingPercentage: "Shareholding Percentage",
  add: "Add",
  updateAndContinue: "Update and Continue",
  legalCompanyName: "Legal Company Name",
  doingBusinessAsOptional: "Doing Business As (DBA/Alias) (optional)",
  registeredLegalEntity: "Registered legal entity of your business",
  companyWebsiteUrl: "Company Website URL (optional)",
  occupation: "Occupation",
  enterOccupation: "Enter your occupation",
  commAddressSameAsRegAddress:
    "Business Communication address same as Registered Address",
  businessCommMailingAddress: "Business Communication/Mailing Address",
  postalZipCode: "Postal/Zip code",
  businessCommAddress: "Business Communication Address",
  businessRegAddress: "Business Registered Address",
  otherInfo: "Other Information",
  timezone: "Timezone",
  businessBeneficiaryOwnershipDoc: "Business Beneficiary Ownership document",
  taxIdDoc: "Tax ID document",
  incorporationDoc: "Incorporation document",
  addressVerificationDoc: "Address verification document",
  goodStandingDoc: "Good standing certificate",
  uploadAndContinue: "Upload and Continue",
  ownership: "Ownership",
  personalInfo: "Personal Information",
  personalDocs: "Personal Documents",
  businessDocs: "Business Documents",
  businessOwnership: "Business Ownership",
  preview: "Preview",
  lastUpdatedOn: "Last Updated On",

  newTicket: "New Ticket",
  chat: "Chat",
  all: "All",
  openTkts: "Open Tickets",
  new: "New",
  closed: "Closed",
  open: "Open",
  selectConvo: "Select a conversation to start chatting.",
  closeTicket: "Close Ticket",
  reopenTicket: "Reopen Ticket",
  delete: "Delete",
  areYouSure: "Are you sure want to",
  theTicket: "the ticket",
  ticket: "ticket",
  reopen: "reopen",
  goToKyc: "Go To KYC",
  typeMessage: "Type a message",

  home: "Home",
  logout: "Logout",

  app: `app`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  cards: `cards`,
  posts: `posts`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
