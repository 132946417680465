import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  activeStep: 0,
  gettingStarted: {
    email: '',
    confirm_email: '',
    first_name: '',
    last_name: '',
    designation:'',
    dob:null,
    primary_contact_no: '',
    secondary_contact_no:'',
    invitation_code: '',
  },
  contactDetails: {
    address: null,
    city: null,
    country: null,
    postal_code: null,
    province: null,
    timezone: null,
  },
  securityDetails: null,
  almostDone: null,
};

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    toggleBusinessOption(state, action) {
      state.business = action.payload;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    onSubmitGettingStarted(state, action) {
      state.gettingStarted = action.payload;
      state.activeStep = 1;
    },
    onSubmitContactDetails(state, action) {
      state.contactDetails = action.payload;
      // state.activeStep = 2;
    },
    clearData(state, action) {
      return initialState;
    },
    // onSubmitSecurityDetails(state, action) {
    //   state.securityDetails = action.payload;
    //   state.activeStep = 3;
    // },
    // onSubmitAlmostDone(state, action) {
    //   state.almostDone = action.payload;
    //   // state.activeStep = 4
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  toggleBusinessOption,
  setActiveStep,
  onSubmitGettingStarted,
  onSubmitContactDetails,
  clearData,
} = slice.actions;

// ----------------------------------------------------------------------
